import {Component, OnInit} from '@angular/core';
import {SiteIdService, AnalyticsService, MetaService} from '@app/services';
import {CommonModule} from '@angular/common';
import {Microsite} from '@app/models';

@Component({
  standalone: true,
  selector: 'app-tems',
  imports: [CommonModule],
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  site: Microsite;
  now: Date = new Date();

  constructor(
    private headerService: MetaService,
    private siteIdService: SiteIdService,
    private analyticsService: AnalyticsService
  ) {
    this.site = this.siteIdService.site;
  }

  ngOnInit() {
    // title/description meta + pageview analytics
    this.setHeaderTags();
    this.analyticsService.recordPageView();
  }

  private setHeaderTags() {
    const canonicalUrl = `https://${this.site.domain}/terms-and-conditions`;
    this.headerService.updateMeta(
      `Terms and Conditions | ${this.site.websiteName}`,
      'Terms and Conditions',
      [
        {
          property: 'og:title',
          content: `Terms and Conditions | ${this.site.websiteName}`,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:description',
          content: 'Terms and Conditions',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${this.site.metaImgDefault.path}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.site.metaImgDefault.alt}`,
        },
        {
          property: 'og:site_name',
          content: this.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.site.metaImgDefault.alt}`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.site.domain,
        },
        {
          name: 'application-name',
          content: this.site.domain,
        },
      ]
    );

    // set canonical url
    this.headerService.setCanonicalUrl(canonicalUrl);
  }
}
