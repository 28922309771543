<div class="width-container lg:mb-10 lg:px-4 pt-14">
  <div class="px-4 pb-11 text-center">
    <h1 class="opt-out mb-2 font-theme-serif text-lg lg:text-2xl font-semibold h-7 lg:h-8">{{ site.websiteName }} Destinations</h1>
    <div class="text-sm lg:text-base h-10 sm:h-7">Uncover Hidden Gems and Iconic Harbors: {{site.cruiselineName ? site.cruiselineName + '\'s' : ''}} Diverse
      Destinations Await</div>
  </div>

  <div class="p-4 py-12 rounded-sm bg-light-gray shadow-lg">
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-12 lg:gap-10 xl:gap-12 max-w-[688px] lg:max-w-[1056px] m-auto">
      @for (destination of destinations; track destination.ID) {
      <div class="mx-auto rounded-sm">
        <app-destination-card [destination]="destination"></app-destination-card>
      </div>
      }
    </div>
  </div>
</div>