import {Component, OnInit} from '@angular/core';
import {MetaService, SiteIdService} from '@app/services';
import {Microsite} from '@app/models';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
  site: Microsite;

  constructor(
    private headerService: MetaService,
    private siteIdService: SiteIdService
  ) {
    this.site = this.siteIdService.site;
  }

  ngOnInit(): void {
    this.setHeaderTags();
  }

  private setHeaderTags() {
    const canonicalUrl = `https://${this.site.domain}/page-not-found`;
    this.headerService.updateMeta(
      `Page Not Found | ${this.site.websiteName}`,
      'Page Not Found',
      [
        {
          property: 'og:title',
          content: 'Page Not Found',
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:description',
          content: 'Page Not Found',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${this.site.metaImgDefault.path}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.site.metaImgDefault.alt}`,
        },
        {
          property: 'og:site_name',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.site.metaImgDefault.alt}`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'application-name',
          content: this.siteIdService.site.domain,
        },
      ]
    );

    // set canonical url
    this.headerService.setCanonicalUrl(canonicalUrl);
  }
}
