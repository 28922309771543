<div class="px-8 py-20 lg:px-24 xl:px-40 lg:w-2/3 m-auto shadow-2xl">
    <h1 class="text-center">Privacy Policy</h1>
    <div class="text-center mb-12">{{site.websiteName}}</div>
    <section class="flex flex-row flex-wrap max-w-screen-xl mx-auto my-16 lg:flex-nowrap">
        <div class="lg:mb-48  max-w-full">
            <div class="font-sans leading-normal mt-4">
                <p class="mb-2">
                    This privacy notice for {{site.websiteName}} Privacy Policy <strong>("Company," "we," "us," or
                        "our")</strong>, describes how and why we might collect, store, use, and/or share
                    <strong>("process")</strong> your information when you use our services
                    <strong>("Services")</strong>, such as when you:
                </p>
                <ul class="list-disc list-inside m-4">
                    <li>Visit our website at <a class="underline-black-ltr-sm lowercase"
                            routerLink="/">{{site.domain}}</a>, or any website of ours that links to this
                        privacy notice</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                <p class="mb-2">
                    <strong>Questions or concerns?</strong>
                    Reading this privacy notice will help you understand your privacy rights and choices. If you do
                    not agree with our policies and practices, please do not use our Services. If you still have any
                    questions or concerns, please contact us at <span
                        class="lowercase">online&#64;{{site.domain}}</span>.
                </p>
            </div>
            <div class="mt-16">
                <h3 class="uppercase lg:text-xl font-500 mb-2">
                    Summary of Key Points
                </h3>
                <p>
                    This summary provides key points from our privacy notice, but you can find out more details about
                    any of these topics by clicking the link following each key point or by using our table of contents
                    below to find the section you are looking for.
                </p>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        Do we process any sensitive personal information?
                    </p>
                    <p>
                        We do not process sensitive personal information.
                    </p>
                </div>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        Do we receive any information from third parties?
                    </p>
                    <p>
                        We do not receive any information from third parties.
                    </p>
                </div>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        How do we process your information?
                    </p>
                    <p>
                        We process your information to provide, improve, and administer our Services, communicate with
                        you, for security and fraud prevention, and to comply with law. We may also process your
                        information for other purposes with your consent. We process your information only when we have
                        a valid legal reason to do so.
                    </p>
                </div>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        In what situations and with which types of parties do we share personal information?
                    </p>
                    <p>
                        We may share information in specific situations and with specific categories of third parties.
                    </p>
                </div>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        How do we keep your information safe?
                    </p>
                    <p>
                        We have organizational and technical processes and procedures in place to protect your personal
                        information. However, no electronic transmission over the internet or information stora
                        technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                        cybercriminals, or other unauthorized third parties will not be able to defeat our security a
                        improperly collect, access, steal, or modify your information.
                    </p>
                </div>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        What are your rights?
                    </p>
                    <p>
                        Depending on where you are located geographically, the applicable privacy law may mean you have
                        certain rights regarding your personal information.
                    </p>
                </div>

                <div class="my-4">
                    <p class="font-bold mb-1">
                        How do you exercise your rights?
                    </p>
                    <p>
                        The easiest way to exercise your rights is by filling out <a routerLink="/contact-us"
                            class="underline-black-ltr-sm">our data subject request form</a> or by contacting us. We
                        will consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                </div>
            </div>
            <div class="mt-16">
                <h3 class="uppercase lg:text-xl font-500 mb-2">
                    1. WHAT INFORMATION DO WE COLLECT?
                </h3>
                <h4 class="lg:text-lg font-500">Personal information you disclose to us</h4>
                <div class="font-sans leading-normal mt-4">
                    <p class="mb-2">
                        <strong>In Short:</strong><span class="italic"> We collect personal information that you provide
                            to us.</span>
                    </p>
                    <p class="mb-2">
                        We collect personal information that you voluntarily provide to us when you register on the
                        Services express an interest in obtaining information about us or our products and Services,
                        when you participate in activities on the Services, or otherwise when you contact us.
                    </p>

                    <div class="my-4">
                        <p class="font-bold mb-2">
                            Personal Information Provided by You
                        </p>
                        <p>
                            The personal information that we collect depends on the context of your interactions with us
                            and the Services, the choices you make, and the products and features you use. The personal
                            information we collect may include the following:
                        </p>
                    </div>

                    <ul class="list-disc list-inside m-4">
                        <li>names</li>
                        <li>phone numbers</li>
                        <li>email addresses</li>
                        <li>mailing addresses</li>
                        <li>contact preferences</li>
                        <li>billing addresses</li>
                        <li>debit/credit card numbers</li>
                    </ul>

                    <div class="my-4">
                        <p class="font-bold mb-2">
                            Sensitive Information
                        </p>
                        <p>
                            We do not process sensitive information.
                        </p>
                    </div>

                    <div class="my-4">
                        <p class="font-bold mb-2">
                            Payment Data
                        </p>
                        <p class="mb-2">
                            We may collect data necessary to process your payment if you make purchases, such as your
                            payment instrument number, and the security code associated with your payment instrument.
                            Credit card payments are processed through USA ePay and Authorize.Net via National Discount
                            Merchant Services.
                        </p>
                        <p>All personal information that you provide to us must be true, complete, and accurate, and you
                            must notify us of any changes to such personal information.</p>
                    </div>

                    <div class="my-12">
                        <h4 class="lg:text-lg font-500">Information automatically collected</h4>
                        <div class="font-sans leading-normal mt-4">
                            <p class="mb-2">
                                <strong>In Short:</strong><span class="italic"> Some information such as your Internet
                                    Protocol (IP) address and/or browser and device characteristics is collected
                                    automatically when you visit our Services.</span>
                            </p>
                            <p class="mb-2">
                                We automatically collect certain information when you visit, use, or navigate the
                                Services. This information does not reveal your specific identity (like your name or
                                contact information) but may include device and usage information, such as your IP
                                address, browser and device characteristics, operating system, language preferences,
                                referring URLs, device name, country, location, information about how and when you use
                                our Services, and other technical information. This information is primarily needed to
                                maintain the security and operation of our Services, and for our internal analytics and
                                reporting purposes.
                            </p>

                            <div class="my-4">
                                <p class="mb-2">Like many businesses, we also collect information through cookies and
                                    similar technologies.</p>
                                <p class="mb-2">The information we collect includes:</p>
                            </div>

                            <ul class="list-disc list-inside m-4">
                                <li class="mb-2"> Log and Usage Data. Log and usage data is service-related, diagnostic,
                                    usage, and performance information our servers automatically collect when you access
                                    or use our Services and which we record in log files. Depending on how you interact
                                    with us, this log data may include your IP address, device information, browser
                                    type, and settings and information about your activity in the Services (such as the
                                    date/time stamps associated with your usage, pages and files viewed, searches, and
                                    other actions you take such as which features you use), device event information
                                    (such as system activity, error reports (sometimes called "crash dumps"), and
                                    hardware settings).</li>
                                <li class="mb-2">Device Data. We collect device data such as information about your
                                    computer, phone, tablet, or other device you use to access the Services. Depending
                                    on the device used, this device data may include information such as your IP address
                                    (or proxy server), device and application identification numbers, location, browser
                                    type, hardware model, Internet service provider and/or mobile carrier, operating
                                    system, and system configuration information./li>
                                <li class="mb-2">Location Data. We collect location data such as information about your
                                    device's location, which can be either precise or imprecise. How much information we
                                    collect depends on the type and settings of the device you use to access the
                                    Services. For example, we may use GPS and other technologies to collect geolocation
                                    data that tells us your current location (based on your IP address). You can opt out
                                    of allowing us to collect this information either by refusing access to the
                                    information or by disabling your Location setting on your device. However, if you
                                    choose to opt out, you may not be able to use certain aspects of the Services.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-16">
                <h3 class="uppercase lg:text-xl font-500 mb-2">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </h3>
                <div class="font-sans leading-normal mt-4">
                    <p class="mb-2">
                        <strong>In Short:</strong><span class="italic"> We process your information to provide, improve,
                            and administer our Services, communicate with you, for security and fraud prevention, and to
                            comply with law. We may also process your information for other purposes with your
                            consent.</span>
                    </p>

                    <div class="my-4">
                        <p class="font-bold mb-2">
                            We process your personal information for a variety of reasons, depending on how you interact
                            with our Services, including:
                        </p>
                        <p>
                            The personal information that we collect depends on the context of your interactions with us
                            and the Services, the choices you make, and the products and features you use. The personal
                            information we collect may include the following:
                        </p>
                    </div>

                    <ul class="list-disc list-inside m-4">
                        <li class="mb-2"><strong>To facilitate account creation and authentication and otherwise manage
                                user accounts.</strong> We may process your information so you can create and log in to
                            your account, as well as keep your account in working order.</li>
                        <li class="mb-2"><strong>To deliver and facilitate delivery of services to the user.</strong> We
                            may process your information to provide you with the requested service.</li>
                        <li class="mb-2"><strong>To respond to user inquiries/offer support to users.</strong> We may
                            process your information to respond to your inquiries and solve any potential issues you
                            might have with the requested service.</li>
                        <li class="mb-2"><strong>To send administrative information to you.</strong> We may process your
                            information to send you details about our products and services, changes to our terms and
                            policies, and other similar information.</li>
                        <li class="mb-2"><strong>To fulfill and manage your orders.</strong> We may process your
                            information to fulfill and manage your orders, payments, returns, and exchanges made through
                            the Services.</li>
                        <li class="mb-2"><strong>To enable user-to-user communications.</strong> We may process your
                            information if you choose to use any of our offerings that allow for communication with
                            another user.</li>
                        <li class="mb-2"><strong>To request feedback.</strong> We may process your information when
                            necessary to request feedback and to contact you about your use of our Services.</li>
                        <li class="mb-2"><strong>To send you marketing and promotional communications.</strong> We may
                            process the personal information you send to us for our marketing purposes, if this is in
                            accordance with your marketing preferences. You can opt out of our marketing emails at any
                            time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
                        </li>
                        <li class="mb-2"><strong>To deliver targeted advertising to you.</strong> We may process your
                            information to develop and display personalized content and advertising tailored to your
                            interests, location, and more.
                        </li>
                        <li class="mb-2"><strong>To post testimonials.</strong> We post testimonials on our Services
                            that may contain personal information.</li>
                        <li class="mb-2"><strong>To protect our Services.</strong> We may process your information as
                            part of our efforts to keep our Services safe and secure, including fraud monitoring and
                            prevention.</li>
                        <li class="mb-2"><strong>To administer prize draws and competitions.</strong> We may process
                            your information to administer prize draws and competitions.</li>
                        <li class="mb-2"><strong>To evaluate and improve our Services, products, marketing, and your
                                experience.</strong> We may process your information when we believe it is necessary to
                            identify usage trends, determine the effectiveness of our promotional campaigns, and to
                            evaluate and improve our Services, products, marketing, and your experience.
                        </li>
                        <li class="mb-2"><strong>To identify usage trends.</strong> We may process information about how
                            you use our Services to better understand how they are being used so we can improve them.
                        </li>
                        <li class="mb-2"><strong>To determine the effectiveness of our marketing and promotional
                                campaigns.</strong> We may process your information to better understand how to provide
                            marketing and promotional campaigns that are most relevant to you.</li>
                        <li class="mb-2"><strong>To comply with our legal obligations.</strong> We may process your
                            information to comply with our legal obligations, respond to legal requests, and exercise,
                            establish, or defend our legal rights.
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-16">
                <h3 class="uppercase lg:text-xl font-500 mb-2">
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </h3>
                <div class="font-sans leading-normal mt-4">
                    <p class="mb-2">
                        <strong>In Short:</strong><span class="italic"> We only process your personal information when
                            we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so
                            under applicable law, like with your consent, to comply with laws, to provide you with
                            services to enter into or fulfill our contractual obligations, to protect your rights, or to
                            fulfill our legitimate business interests.</span>
                    </p>

                    <p class="italic underline font-bold">If you are located in Canada, this section applies to you.</p>

                    <div class="my-4">
                        <p>
                            We may process your information if you have given us specific permission (i.e., express
                            consent) to use your personal information for a specific purpose, or in situations where
                            your permission can be inferred (i.e., implied consent). You can withdraw your consent at
                            any time.<br><br>

                            In some exceptional cases, we may be legally permitted under applicable law to process your
                            information without your consent, including, for example:
                        </p>
                    </div>

                    <ul class="list-disc list-inside m-4">
                        <li>If collection is clearly in the interests of an individual and consent cannot be obtained in
                            a timely way</li>
                        <li>For investigations and fraud detection and prevention</li>
                        <li>For business transactions provided certain conditions are met</li>
                        <li>If it is contained in a witness statement and the collection is necessary to assess,
                            process, or settle an insurance claim</li>
                        <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                        <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of
                            financial abuse</li>
                        <li>If it is reasonable to expect collection and use with consent would compromise the
                            availability or the accuracy of the information and the collection is reasonable for
                            purposes related to investigating a breach of an agreement or a contravention of the laws of
                            Canada or a province</li>
                        <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the
                            court relating to the production of records</li>
                        <li>If it was produced by an individual in the course of their employment, business, or
                            profession and the collection is consistent with the purposes for which the information was
                            produced</li>
                        <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                        <li>If the information is publicly available and is specified by the regulations</li>
                    </ul>
                </div>

                <div class="mt-16">
                    <h3 class="uppercase lg:text-xl font-500 mb-2">
                        4. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                    </h3>
                    <div class="font-sans leading-normal mt-4">
                        <p class="mb-2">
                            <strong>In Short:</strong><span class="italic"> We may share information in specific
                                situations described in this section and/or with the following categories of third
                                parties.</span>
                        </p>

                        <div class="mt-4">
                            <p>
                                <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may
                                share your data with third-party vendors, service providers, contractors, or agents
                                <strong>("third parties")</strong> who perform services for us or on our behalf and
                                require access to such information to do that work. We have contracts in place with our
                                third parties, which are designed to help safeguard your personal information. This
                                means that they cannot do anything with your personal information unless we have
                                instructed them to do it. They will also not share your personal information with any
                                organization apart from us. They also commit to protect the data they hold on our behalf
                                and to retain it for the period we instruct. The categories of third parties we may
                                share personal information with are as follows:
                            </p>
                        </div>

                        <ul class="list-disc list-inside m-4">
                            <li>Ad Networks</li>
                            <li>Sales & Marketing Tools</li>
                        </ul>

                        <p class="mt-6">We also may need to share your personal information in the following situations:
                        </p>

                        <ul class="list-disc list-inside m-4">
                            <li class="mb-2"><strong>Business Transfers.</strong> We may share or transfer your
                                information in connection with, or during negotiations of, any merger, sale of company
                                assets, financing, or acquisition of all or a portion of our business to another
                                company.
                            </li>
                            <li class="mb-2"><strong>Affiliates.</strong> We may share your information with our
                                affiliates, in which case we will require those affiliates to honor this privacy notice.
                                Affiliates include our parent company and any subsidiaries, joint venture partners, or
                                other companies that we control or that are under common control with us.</li>
                            <li class="mb-2"><strong>Business Partners.</strong> We may share your information with our
                                business partners to offer you certain products, services, or promotions.</li>
                        </ul>
                    </div>
                </div>

                <div class="mt-16">
                    <h3 class="uppercase lg:text-xl font-500 mb-2">
                        5. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                    </h3>
                    <div class="font-sans leading-normal mt-4">
                        <p class="mb-2">
                            <strong>In Short:</strong> <span class="italic"> We may use cookies and other tracking
                                technologies to collect and store your information.</span>
                        </p>
                        <p>
                            We may use cookies and similar tracking technologies (like web beacons and pixels) to access
                            or store information. Specific information about how we use such technologies and how you
                            can refuse certain cookies is set out in our Cookie Notice.
                        </p>
                    </div>
                </div>

                <div class="mt-16">
                    <h3 class="uppercase lg:text-xl font-500 mb-2">
                        6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </h3>
                    <div class="font-sans leading-normal mt-4">
                        <p class="mb-2">
                            <strong>In Short:</strong> <span class="italic"> We keep your information for as long as
                                necessary to fulfill the purposes outlined in this privacy notice unless otherwise
                                required by law.</span>
                        </p>
                        <p class="mb-2">
                            We will only keep your personal information for as long as it is necessary for the purposes
                            set out in this privacy notice, unless a longer retention period is required or permitted by
                            law (such as tax, accounting, or other legal requirements). No purpose in this notice will
                            require us keeping your personal information for longer than the period of time in which
                            users have an account with us.
                        </p>
                        <p class="mb-2">
                            When we have no ongoing legitimate business need to process your personal information, we
                            will either delete or anonymize such information, or, if this is not possible (for example,
                            because your personal information has been stored in backup archives), then we will securely
                            store your personal information and isolate it from any further processing until deletion is
                            possible.
                        </p>
                    </div>
                </div>

                <div class="mt-16">
                    <h3 class="uppercase lg:text-xl font-500 mb-2">
                        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </h3>
                    <div class="font-sans leading-normal mt-4">
                        <p class="mb-2">
                            <strong>In Short:</strong> <span class="italic"> We aim to protect your personal information
                                through a system of organizational and technical security measures.</span>
                        </p>
                        <p class="mb-2">
                            We have implemented appropriate and reasonable technical and organizational security
                            measures designed to protect the security of any personal information we process. However,
                            despite our safeguards and efforts to secure your information, no electronic transmission
                            over the Internet or information storage technology can be guaranteed to be 100% secure, so
                            we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
                            parties will not be able to defeat our security and improperly collect, access, steal, or
                            modify your information. Although we will do our best to protect your personal information,
                            transmission of personal information to and from our Services is at your own risk. You
                            should only access the Services within a secure environment.
                        </p>
                    </div>
                </div>

                <div class="mt-16">
                    <h3 class="uppercase lg:text-xl font-500 mb-2">
                        8. DO WE COLLECT INFORMATION FROM MINORS?
                    </h3>
                    <div class="font-sans leading-normal mt-4">
                        <p class="mb-2">
                            <strong>In Short:</strong> <span class="italic"> We do not knowingly collect data from or
                                market to children under 18 years of age.</span>
                        </p>
                        <p class="mb-2">
                            We do not knowingly solicit data from or market to children under 18 years of age. By using
                            the Services, you represent that you are at least 18 or that you are the parent or guardian
                            of such a minor and consent to such minor dependents use of the Services. If we learn that
                            personal information from users less than 18 years of age has been collected, we will
                            deactivate the account and take reasonable measures to promptly delete such data from our
                            records. If you become aware of any data we may have collected from children under age 18,
                            please contact us at <span class="lowercase">online&#64;{{site.domain}}</span>.
                        </p>
                    </div>
                </div>

                <div class="mt-16">
                    <h3 class="uppercase lg:text-xl font-500 mb-2">
                        9. WHAT ARE YOUR PRIVACY RIGHTS?
                    </h3>
                    <div class="font-sans leading-normal mt-4">
                        <p class="mb-2">
                            <strong>In Short:</strong> <span class="italic"> In some regions, such as Canada, you have
                                rights that allow you greater access to and control over your personal information. You
                                may review, change, or terminate your account at any time.</span>
                        </p>
                        <p class="mb-2">
                            In some regions (like Canada), you have certain rights under applicable data protection
                            laws. These may include the right (i) to request access and obtain a copy of your personal
                            information, (ii) to request rectification or erasure; (iii) to restrict the processing of
                            your personal information; and (iv) if applicable, to data portability. In certain
                            circumstances, you may also have the right to object to the processing of your personal
                            information. You can make such a request by contacting us by using the contact details
                            provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                        </p>
                        <p class="mb-2">
                            We will consider and act upon any request in accordance with applicable data protection
                            laws.
                        </p>
                        <p class="mb-2">
                            If you are located in the EEA or UK and you believe we are unlawfully processing your
                            personal information, you also have the right to complain to your local data protection
                            supervisory authority. You can find their contact details here: <a
                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                target="_blank" rel="noreferrer"
                                class="underline-black-ltr-sm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                        </p>
                        <p class="mb-3">
                            If you are located in Switzerland, the contact details for the data protection authorities
                            are available here:
                            <a href=">https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noreferrer"
                                class="underline-black-ltr-sm">https://www.edoeb.admin.ch/edoeb/en/home.html</a>
                        </p>
                        <div class="my-10">
                            <p class="mb-2">
                                <strong class="underline">Withdrawing your consent: </strong> If we are relying on your
                                consent to process your personal information, which may be express and/or implied
                                consent depending on the applicable law, you have the right to withdraw your consent at
                                any time. You can withdraw your consent at any time by contacting us by using the
                                contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                                below.
                            </p>
                            <p class="mb-3">
                                However, please note that this will not affect the lawfulness of the processing before
                                its withdrawal nor, when applicable law allows, will it affect the processing of your
                                personal information conducted in reliance on lawful processing grounds other than
                                consent.
                            </p>
                            <p class="my-8">
                                <strong class="underline">Opting out of marketing and promotional communications:
                                </strong> You can unsubscribe from our marketing and promotional communications at any
                                time by clicking on the unsubscribe link in the emails that we send, or by contacting us
                                using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                                below. You will then be removed from the marketing lists. However, we may still
                                communicate with you for example, to send you service-related messages that are
                                necessary for the administration and use of your account, to respond to service
                                requests, or for other non-marketing purposes.
                            </p>
                        </div>
                        <div class="my-12">
                            <h4 class="lg:text-lg font-500">Account Information</h4>
                            <p class="mb-2">If you would at any time like to review or change the information in your
                                account or terminate your account, you can:</p>
                            <ul class="list-disc list-inside m-4">
                                <li>Contact us using the contact information provided./li>
                            </ul>
                            <p class="mb-3">Upon your request to terminate your account, we will deactivate or delete
                                your account and information from our active databases. However, we may retain some
                                information in our files to prevent fraud, troubleshoot problems, assist with any
                                investigations, enforce our legal terms and/or comply with applicable legal
                                requirements.</p>
                            <p class="my-8">
                                <strong class="underline">Cookies and similar technologies:</strong> Most Web browsers
                                are set to accept cookies by default. If you prefer, you can usually choose to set your
                                browser to remove cookies and to reject cookies. If you choose to remove cookies or
                                reject cookies, this could affect certain features or services of our Services. To opt
                                out of interest-based advertising by advertisers on our Services visit <a
                                    href=" https://www.aboutads.info/choices/. " class="underline-black-ltr-sm"
                                    target="_blank" rel="noreferrer"> https://www.aboutads.info/choices/. </a>
                            </p>
                            <p>If you have questions or comments about your privacy rights, you may email us at <span
                                    class="lowercase">online&#64;{{site.domain}}</span></p>
                        </div>
                        <div class="mt-16">
                            <h3 class="uppercase lg:text-xl font-500 mb-2">
                                10. CONTROLS FOR DO-NOT-TRACK FEATURES
                            </h3>
                            <div class="font-sans leading-normal mt-4">
                                <p class="mb-2">
                                    Most web browsers and some mobile operating systems and mobile applications include
                                    a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy
                                    preference not to have data about your online browsing activities monitored and
                                    collected. At this stage no uniform technology standard for recognizing and
                                    implementing DNT signals has been finalized. As such, we do not currently respond to
                                    DNT browser signals or any other mechanism that automatically communicates your
                                    choice not to be tracked online. If a standard for online tracking is adopted that
                                    we must follow in the future, we will inform you about that practice in a revised
                                    version of this privacy notice.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <h3 class="uppercase lg:text-xl font-500 mb-2">
                            11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                        </h3>
                        <div class="font-sans leading-normal mt-4">
                            <p class="mb-2">
                                <strong>In Short:</strong> <span class="italic"> Yes, if you are a resident of
                                    California, you are granted specific rights regarding access to your personal
                                    information.</span>
                            </p>
                            <p class="mb-2">
                                California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits
                                our users who are California residents to request and obtain from us, once a year and
                                free of charge, information about categories of personal information (if any) we
                                disclosed to third parties for direct marketing purposes and the names and addresses of
                                all third parties with which we shared personal information in the immediately preceding
                                calendar year. If you are a California resident and would like to make such a request,
                                please submit your request in writing to us using the contact information provided
                                below.
                            </p>
                            <p class="mb-2">
                                If you are under 18 years of age, reside in California, and have a registered account
                                with Services, you have the right to request removal of unwanted data that you publicly
                                post on the Services. To request removal of such data, please contact us using the
                                contact information provided below and include the email address associated with your
                                account and a statement that you reside in California. We will make sure the data is not
                                publicly displayed on the Services, but please be aware that the data may not be
                                completely or comprehensively removed from all our systems (e.g., backups, etc.).
                            </p>
                            <div class="my-12">
                                <h4 class="lg:text-lg font-500 mb-4">CCPA Privacy Notice</h4>
                                <p class="mb-2">The California Code of Regulations defines a "resident" as:</p>
                                <ol class="list-decimal list-inside m-4">
                                    <li>
                                        Every individual who is in the State of California for other than a temporary or
                                        transitory purpose and <br>
                                    </li>
                                    <li>
                                        Every individual who is domiciled in the State of California who is outside the
                                        State of California for a temporary or transitory purpose
                                    </li>
                                </ol>
                                <p class="mb-2">All other individuals are defined as "non-residents."</p>
                                <p class="mb-2">If this definition of "resident" applies to you, we must adhere to
                                    certain rights and obligations regarding your personal information.</p>
                                <strong class="mb-1">What categories of personal information do we collect?</strong>
                                <p>We have collected the following categories of personal information in the past twelve
                                    (12) months:</p>
                            </div>
                            <div class="w-full overflow-x-auto">
                                <table cellspacing="0" cellpadding="0" class="mb-8">
                                    <tbody>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span><b>Category</b></span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span><b>Examples</b></span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span><b>Collected</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>A. Identifiers</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Contact details, such as real name, alias, postal address,
                                                        telephone or mobile contact number, unique personal identifier,
                                                        online identifier, Internet Protocol address, email address, and
                                                        account name</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>YES</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>B. Personal information categories listed in the California
                                                        Customer Records statute</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Name, contact information, education, employment, employment
                                                        history, and financial information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>YES</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>C. Protected classification characteristics under California or
                                                        federal law</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Gender and date of birth</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>YES</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>D. Commercial information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Transaction information, purchase history, financial details, and
                                                        payment information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>YES</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>E. Biometric information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Fingerprints and voiceprints</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>NO</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>F. Internet or other similar network activity</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Browsing history, search history, online behavior, interest data,
                                                        and interactions with our and other websites, applications, systems,
                                                        and advertisements</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>YES</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>G. Geolocation data</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Device location</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>NO</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>H. Audio, electronic, visual, thermal, olfactory, or similar
                                                        information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Images and audio, video or call recordings created in connection
                                                        with our business activities</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>NO</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>I. Professional or employment-related information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Business contact details in order to provide you our Services at a
                                                        business level or job title, work history, and professional
                                                        qualifications if you apply for a job with us</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>NO</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-t-2 border-r-2 border-l-2 border-black p-4">
                                                <p><span>J. Education Information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span>Student records and directory information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>NO</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" class="border-2 border-black p-4">
                                                <p><span>K. Inferences drawn from other personal information</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-b-2 border-black p-4">
                                                <p><span>Inferences drawn from any of the collected personal information
                                                        listed above to create a profile or summary about, for example, an
                                                        individuals preferences and characteristics</span></p>
                                            </td>
                                            <td valign="middle" class="border-t-2 border-r-2 border-b-2 border-black p-4">
                                                <p><span></span><br></p>
                                                <p><span>YES</span></p>
                                                <p><span></span><br></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p class="mb-2">We may also collect other personal information outside of these categories
                                through instances where you interact with us in person, online, or by phone or mail in
                                the context of:</p>

                            <ul class="list-disc list-inside m-4 mb-8">
                                <li>Receiving help through our customer support channels;</li>
                                <li>Participation in customer surveys or contests; and</li>
                                <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                            </ul>
                            <strong class="mb-2">How do we use and share your personal information?</strong>
                            <p>{{site.websiteName}} Privacy Policy collects and shares your personal information through:</p>

                            <ul class="list-disc list-inside m-4">
                                <li>Targeting cookies/Marketing cookies</li>
                                <li>Social media cookies</li>
                                <li>Beacons/Pixels/Tags</li>
                                <li>Click redirects: __________.
                                </li>
                                <li>Social media plugins: __________. We use social media features, such as a "Like"
                                    button, and widgets, such as a "Share" button, in our Services. Such features may
                                    process your Internet Protocol (IP) address and track which page you are visiting on
                                    our website. We may place a cookie to enable the feature to work correctly. If you
                                    are logged in on a certain social media platform and you interact with a widget or
                                    button belonging to that social media platform, this information may be recorded to
                                    your profile of such social media platform. To avoid this, you should log out from
                                    that social media platform before accessing or using the Services. Social media
                                    features and widgets may be hosted by a third party or hosted directly on our
                                    Services. Your interactions with these features are governed by the privacy notices
                                    of the companies that provide them. By clicking on one of these buttons, you agree
                                    to the use of this plugin and consequently the transfer of personal information to
                                    the corresponding social media service. We have no control over the essence and
                                    extent of these transmitted data or their additional processing.</li>
                            </ul>

                            <p class="mb-2">More information about our data collection and sharing practices can be
                                found in this privacy notice.</p>
                            <p class="mb-2">You may contact us by email at <span
                                    class="lowercase">online&#64;{{site.domain}}</span>, or by referring to the
                                contact details at the bottom of this document.</p>
                            <p class="mb-2">If you are using an authorized agent to exercise your right to opt out we
                                may deny a request if the authorized agent does not submit proof that they have been
                                validly authorized to act on your behalf.</p>
                            <div class="my-10">
                                <strong class="mt-10 mb-2">Will your information be shared with anyone else?</strong>
                                <p class="mb-2">We may disclose your personal information with our service providers
                                    pursuant to a written contract between us and each service provider. Each service
                                    provider is a for-profit entity that processes the information on our behalf.</p>
                                <p class="mb-2">We may use your personal information for our own business purposes, such
                                    as for undertaking internal research for technological development and
                                    demonstration. This is not considered to be "selling" of your personal information.
                                </p>
                                <p class="mb-2">{{site.websiteName}} Privacy Policy has not sold any personal information to third
                                    parties for a business or commercial purpose in the preceding twelve (12) months.
                                    {{site.websiteName}} Privacy Policy has disclosed the following categories of personal
                                    information to third parties for a business or commercial purpose in the preceding
                                    twelve (12) months:</p>

                                <ul class="list-disc list-inside m-4">
                                    <li>Category A - Identifiers, such as contact details like your real name, alias,
                                        postal address, telephone or mobile contact number, unique personal identifier,
                                        online identifier, Internet Protocol address, email address, and account name.
                                    </li>
                                    <li>Category B - Personal Information, as defined in the California Customer Records
                                        law, such as your name, contact information, education, employment, employment
                                        history, and financial information.</li>
                                    <li>Category C - Characteristics of protected classifications under California or
                                        federal law, such as gender or date of birth.</li>
                                </ul>

                                <p class="mb-2">The categories of third parties to whom we disclosed personal
                                    information for a business or commercial purpose can be found under "WHEN AND WITH
                                    WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".
                                </p>
                            </div>
                            <strong class="mt-10 mb-2">Your rights with respect to your personal data</strong>
                            <p class="mb-1 underline">Right to request deletion of the data Request to delete</p>
                            <p class="mb-2">You can ask for the deletion of your personal information. If you ask us to
                                delete your personal information, we will respect your request and delete your personal
                                information, subject to certain exceptions provided by law, such as (but not limited to)
                                the exercise by another consumer of his or her right to free speech, our compliance
                                requirements resulting from a legal obligation, or any processing that may be required
                                to protect against illegal activities.</p>
                            <p class="mb-1 underline">Right to be informed Request to know</p>
                            <p class="mb1">Depending on the circumstances, you have a right to know:</p>
                            <ul class="list-disc list-inside m-4">
                                <li>whether we collect and use your personal information;</li>
                                <li>the categories of personal information that we collect;</li>
                                <li>the purposes for which the collected personal information is used;</li>
                                <li>whether we sell your personal information to third parties;</li>
                                <li>the categories of personal information that we sold or disclosed for a business
                                    purpose;</li>
                                <li>the categories of third parties to whom the personal information was sold or
                                    disclosed for a business purpose; and</li>
                                <li>the business or commercial purpose for collecting or selling personal information.
                                </li>
                            </ul>
                            <p class="mb-2">In accordance with applicable law, we are not obligated to provide or delete
                                consumer information that is de-identified in response to a consumer request or to
                                re-identify individual data to verify a consumer request.</p>
                            <p class="mb-1 underline">Right to Non-Discrimination for the Exercise of a Consumer's
                                Privacy Rights</p>
                            <p class="mb-2">We will not discriminate against you if you exercise your privacy rights.
                            </p>
                            <p class="mb-1 underline">Verification process</p>
                            <p class="mb-2">Upon receiving your request, we will need to verify your identity to
                                determine you are the same person about whom we have the information in our system.
                                These verification efforts require us to ask you to provide information so that we can
                                match it with information you have previously provided us. For instance, depending on
                                the type of request you submit, we may ask you to provide certain information so that we
                                can match the information you provide with the information we already have on file, or
                                we may contact you through a communication method (e.g., phone or email) that you have
                                previously provided to us. We may also use other verification methods as the
                                circumstances dictate.</p>
                            <p class="mb-2">We will only use personal information provided in your request to verify
                                your identity or authority to make the request. To the extent possible, we will avoid
                                requesting additional information from you for the purposes of verification. However, if
                                we cannot verify your identity from the information already maintained by us, we may
                                request that you provide additional information for the purposes of verifying your
                                identity and for security or fraud-prevention purposes. We will delete such additionally
                                provided information as soon as we finish verifying you.</p>
                            <p class="mb-1 underline">Other privacy rights</p>
                            <ul class="list-disc list-inside m-4">
                                <li>You may object to the processing of your personal information.</li>
                                <li>You may request correction of your personal data if it is incorrect or no longer
                                    relevant, or ask to restrict the processing of the information.</li>
                                <li>You can designate an authorized agent to make a request under the CCPA on your
                                    behalf. We may deny a request from an authorized agent that does not submit proof
                                    that they have been validly authorized to act on your behalf in accordance with the
                                    CCPA.</li>
                                <li>You may request to opt out from future selling of your personal information to third
                                    parties. Upon receiving an opt-out request, we will act upon the request as soon as
                                    feasibly possible, but no later than fifteen (15) days from the date of the request
                                    submission.</li>
                            </ul>
                            <p class="mb-2">To exercise these rights, you can contact us by email at <span
                                    class="lowercase">online&#64;{{site.domain}}</span>, or by referring to the
                                contact details at the bottom of this document. If you have a complaint about how we
                                handle your data, we would like to hear from you.</p>
                            <div class="my-10">
                                <strong class="mb-2">Financial Incentives</strong>
                                <p class="mb-2">"Financial incentive" means a program, benefit, or other offering,
                                    including payments to consumers as compensation, for the disclosure, deletion, or
                                    sale of personal information.</p>
                                <p class="mb-2">The law permits financial incentives or a price or service difference if
                                    it is reasonably related to the value of the consumer data. A business must be able
                                    to explain how the financial incentive or price or service difference is reasonably
                                    related to the value of the consumer data. The explanation must include:</p>
                                <ul class="list-disc list-inside m-4">
                                    <li>a good-faith estimate of the value of the consumer's data that forms the basis
                                        for offering the financial incentive or price or service difference; and</li>
                                    <li>a description of the method the business used to calculate the value of the
                                        consumer's data.</li>
                                </ul>
                                <p class="mb-2">We may decide to offer a financial incentive (e.g., price or service
                                    difference) in exchange for the retention or sale of a consumer's personal
                                    information.</p>
                                <p class="mb-2">If we decide to offer a financial incentive, we will notify you of such
                                    financial incentive and explain the price difference, as well as material terms of
                                    the financial incentive or price of service difference, including the categories of
                                    personal information that are implicated by the financial incentive or price or
                                    service difference.</p>
                                <p class="mb-2">If you choose to participate in the financial incentive you can withdraw
                                    from the financial incentive at any time by emailing us at <span
                                        class="lowercase">online&#64;{{site.domain}}</span>, or by referring to
                                    the contact details at the bottom of this document.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <h3 class="uppercase lg:text-xl font-500 mb-2">
                            12. DO WE MAKE UPDATES TO THIS NOTICE?
                        </h3>
                        <div class="font-sans leading-normal mt-4">
                            <p class="mb-2">
                                <strong>In Short:</strong> <span class="italic"> Yes, we will update this notice as
                                    necessary to stay compliant with relevant laws.</span>
                            </p>
                            <p class="mb-2">
                                We may update this privacy notice from time to time. The updated version will be
                                indicated by an updated "Revised" date and the updated version will be effective as soon
                                as it is accessible. If we make material changes to this privacy notice, we may notify
                                you either by prominently posting a notice of such changes or by directly sending you a
                                notification. We encourage you to review this privacy notice frequently to be informed
                                of how we are protecting your information.
                            </p>
                        </div>
                    </div>
                    <div class="mt-16">
                        <h3 class="uppercase lg:text-xl font-500 mb-2">
                            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </h3>
                        <div class="font-sans leading-normal mt-4">
                            <p class="mb-2">
                                If you have questions or comments about this notice, you may contact our Data Protection
                                Officer (DPO), by email at <span
                                    class="lowercase">online&#64;{{site.domain}}</span>, or by post to:
                            </p>
                            <p class="ml-6 mb-2">
                                {{site.websiteName}} Privacy Policy <br>
                                Data Protection Officer <br>
                                1640 East River Road <br>
                                #115<br>
                                Tucson , AZ 85718<br>
                                United States
                            </p>
                        </div>
                    </div>
                    <div class="mt-16">
                        <h3 class="uppercase lg:text-xl font-500 mb-2">
                            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                        </h3>
                        <div class="font-sans leading-normal mt-4">
                            <p class="mb-2">
                                Based on the applicable laws of your country, you may have the right to request access
                                to the personal information we collect from you, change that information, or delete it.
                                To request to review, update, or delete your personal information, <a
                                    routerLink="/contact-us" class="underline-black-ltr-sm">please submit a request form
                                    by clicking here.</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>