<nav #root aria-label="Site Navigation" (keydown.escape)="handleMobileEscape()" (focusout)="navFocusOut($event)">
  <ul class="opt-out">
    <li>
      <button (click)="toggleMobileMenu()" aria-label="Navigation Menu" [attr.aria-expanded]="mobileNavOpen"
        aria-controls="mobile-menu" [class]="mobileNavOpen ? 'bg-primary-200' : ''"
        class="lg:hidden w-11 flex justify-center p-3 text-white">
        <icon-hamburger></icon-hamburger>
      </button>
      <ul [class]="mobileNavOpen ? 'block' : 'hidden'" id="mobile-menu"
        class="opt-out absolute z-10 lg:static lg:flex top-full left-0 right-0 bg-primary-100 lg:bg-transparent">
        @switch (siteID) {
          @case (Site.Alaska) {
            <li>
              <a routerLink="/promotions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Promotions</a>
            </li>
            <li>
              <a routerLink="/cruise-lines" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Cruise Lines</a>
            </li>
            <li>
              <a routerLink="/why-choose-an-alaska-cruise-tour" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Cruisetours</a>
            </li>
            <li>
              <a routerLink="/frequently-asked-questions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">FAQs</a>
            </li>
          }
          @case (Site.CI) {
            <li>
              <a routerLink="/destinations" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Destinations</a>
            </li>
            <li>
              <a routerLink="/promotions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Promotions</a>
            </li>
            <li>
              <a routerLink="/cruise-lines" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Cruise Lines</a>
            </li>
            <li>
              <a routerLink="/frequently-asked-questions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">FAQs</a>
            </li>
          }
          @case (Site.Silversea) {
            <li>
              <a routerLink="/destinations" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Destinations</a>
            </li>
            <li>
              <a routerLink="/promotions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Promotions</a>
            </li>
            <li>
              <a routerLink="/expeditions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Expeditions</a>
            </li>
            <li>
              <a routerLink="/ships" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Ships</a>
            </li>
          }
          @default {
            <li>
              <a routerLink="/destinations" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Destinations</a>
            </li>
            <li>
              <a routerLink="/promotions" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Promotions</a>
            </li>
            <li>
              <a routerLink="/ships" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">Ships</a>
            </li>
            <li>
              <a routerLink="/about-cruiseline" [class]="alt ? 'text-black hover:bg-light-gray' : 'text-white hover:bg-primary-200'" class="opt-out h-full w-full flex items-center justify-center px-3.5 py-3 border-b lg:border-0 border-primary-100  rounded-t-sm font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold bg-primary-200 lg:bg-transparent transition-colors">About {{siteID}}</a>
            </li>
          }
        }
        <li [class]="alt ? 'alt' : ''" (keydown.escape)="handleEscape($event)" class="hidden lg:block relative lg:ml-3">
          <button (click)="toggleMenu($event, 4)" [attr.aria-expanded]="activeMenuIdx === 4"
            aria-controls="contact-menu" id="contact-menu-btn"
            class="flex items-center justify-center px-3.5 py-3 h-full w-full font-theme-sans uppercase lg:text-xs xl:text-sm tracking-wider font-bold border-2 rounded-sm border-tertiary-100 bg-transparent">
            <span class="text-white">
              <i [class]="activeMenuIdx === 4 ? 'active' : ''" class="mr-2">
                <icon-phone [width]="12"></icon-phone>
              </i>
              <span>{{ phone }}</span>
              <i [class]="activeMenuIdx === 4 ? 'active' : ''" class="indicator ml-2">
                <icon-chevron [width]="12"></icon-chevron>
              </i>
            </span>
          </button>
          <ul id="contact-menu" [class]="activeMenuIdx === 4 ? 'open' : ''"
            class="sub-menu opt-out two-items z-10 absolute top-full left-0 right-0 overflow-hidden text-center lg:text-sm shadow-md rounded-b-sm bg-off-white">
            <li class="border-b-2 border-light-gray">
              <a href="tel:+{{tel}}" class="opt-out flex justify-center w-full p-3.5">Call Now</a>
            </li>
            <li>
              <a routerLink="/contact-us" href="#" class="opt-out flex items-center justify-center w-full p-3.5">
                <i [class]="activeMenuIdx === 4 ? 'active' : ''" class="relative -top-px mr-2">
                  <icon-mail [width]="14"></icon-mail>
                </i>
                <span>Email</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>